@import "@/styles/echart.scss";
$app-path: "/";
* {
  margin: 0;
  padding: 0;
}

html,
body,
#app {
  height: 100%;
}

body {
  background: #171b22;
  overflow: hidden;
  font-family: "PingFang SC","Microsoft YaHei","Helvetica Neue",Arial,sans-serif!important;
  -webkit-font-smoothing: antialiased;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: hsla(220, 4%, 58%, .3);
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: hsla(220, 4%, 58%, .3);
}

a {
  text-decoration: none;
}

img {
  user-select: none;
}

.img{
  margin-right: 5px;
}
.block{
  margin: 0 auto;
  width:90%;
  display: block;
}

a{
  text-decoration: none;
  font-size: 12px;
  color:#409EFF;
}

.el-message-box__wrapper {
  background-color: #000;
}
.el-select-dropdown {
  border-radius: 0;
  border:none;
  color: #bcc9d4;
  background-color: #27343e;
  color: #bcc9d4;
}
.el-select-dropdown__item{
  font-size: 12px;
}
.el-select-dropdown__item.selected,.el-select-dropdown__item.hover{
  font-weight: normal;
  background-color: rgba(0,192,222,.1);
}
.el-select-dropdown__item.hover{
  color: #fff;
}
.el-select-dropdown__item.selected{
  color: #fff;
}
.el-input-group__append, .el-input-group__prepend{
  background-color: #0f1014;
  border-color: #282e3a;
}

.el-collapse-item__header.is-active{
  color:#fff;
}

.el-collapse-item__wrap{
  background-color:#000;
  border-bottom: none;
}
.el-radio__input, .params .el-radio__label,.params input{
  font-size: 12px;
}
.avue-crud{
  .el-table th, .el-table tr,.el-table, .el-table__expanded-cell {
    background-color: transparent !important;
    color:#859094 !important;
  }
  .el-table td, .el-table th.is-leaf {
    border-color: #859094 !important;
  }
  .hover-row td,.hover-row th{
    background-color: transparent !important;
    border-bottom: none;
  }
  .el-table__fixed-right::before, .el-table__fixed::before,.el-table::before{
    display: none;
  }
  .el-table td, .el-table th.is-leaf {
    border-bottom:none;
  }
}
.avue-empty__desc{
  color:#fff;
}
.el-form-item__label{
  color:#fff !important;
}

.el-dialog ,.avue-group__item{
  background: #1b1e25;
}
.el-message-box{
  background: #1b1e25;
  border-color:  #1b1e25;
}
.el-message-box__title{
  color:#fff;
}
.el-dialog__title{
  color:#fff;
  font-size: 16px;
}
.el-collapse-item__arrow.is-active{
  color:#fff;
}
.el-collapse{
  border-top:none;
  border-bottom: none;
}
input{
  border-width: 2px;
  border-radius: 0;
}

.avue-crud{
  width: 90%;
  margin-top: 10px;
  &__menu{
    background-color: inherit;
  }
}
.el-radio{
  width: 100%;
  line-height: 25px;
  font-size: 14px;
}
.el-color-picker__trigger,.el-dialog .el-color-picker__trigger{
  border:none;
}
.el-collapse-item__arrow{
  position: absolute;
  left:10px;
  line-height: 40px;
  color:#bcc9d4;
}


.el-form-item{
  margin-top: 10px;
}
.el-pagination__total{
  color:#fff;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  background-color:transparent;
  color: #fff;
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: transparent;
  border:1px solid #409EFF;
}
.el-form-item__label,.el-dialog .el-form-item__label{
  color:#859094;
  padding-left: 20px;
  font-size: 12px;
}
.el-form-item__content, .el-dialog  .el-form-item__content{
  padding-right: 20px;
}
.el-checkbox__inner,input,.el-slider__runway,textarea,
.el-dialog input,.el-switch__core,.el-dialog .el-slider__runway,.el-dialog textarea{
  background-color: #0f1014 !important;
  color:#859094 !important;
  border-color:#282e3a !important;
}
.el-switch.is-checked .el-switch__core {
  border-color: #409EFF  !important;
  background-color: #409EFF  !important;
}
.el-button{
  border-radius: 0;
}
.el-radio__input.is-checked+.el-radio__label{
  color:#2681ff;
}
.el-radio__input.is-checked .el-radio__inner{
  border-color: #2681ff;
  background: #2681ff;
}
.el-button--primary{
  background-color: transparent;
  color:#2681ff;
  border-color:#2681ff;
  &:hover,&:focus{
    color: #fff;
    background-color: #4f9eff;
    border-color: #4f9eff;
  }
}
.el-button--danger{
  background-color: transparent;
  color:#f56c6c;
  border-color:#f56c6c;
  &:hover,&:focus{
    background:transparent;
    border-color: #f56c6c;
    color:#f56c6c;
  }
}
.el-button--success{
  background-color: transparent;
  color:#67C23A;
  border-color:#67C23A;
  &:hover,&:focus{
    background:transparent;
    border-color: #67C23A;
    color:#67C23A;
  }
}
.el-button--default{
  background-color: transparent;
  color:#859094;
  border-color:#859094;
  &:hover,&:focus{
    background:transparent;
    border-color: #859094;
    color:#859094;
  }
}
.el-collapse-item__content{
  padding: 0;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover{
  background-color: initial;
  color:#fff;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
  background-color: initial;
  color:#fff;
}
.el-dialog__title{
  color:#999 !important;
}
.el-collapse-item__header{
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  background: transparent;
  color:#bcc9d4;
  font-weight: 300;
  font-size: 12px;
  border-color: #282e3a;
}
 
.el-input-number__decrease,.el-input-number__increase{
  border-color:#333 !important;
}
.icon-gauge{
  font-size: 12px !important;
}
.el-card{
  background-color: transparent;
}
.el-input__inner,.el-textarea__inner,.el-input-group__append, .el-input-group__prepend{
  border-color: #000 !important;
  border-radius: 0;
  color:#fff !important;
  &:hover{
    border-color: #2681ff !important;
  }
}
.el-form-item--mini .el-color-picker--mini, .el-form-item--mini .el-color-picker--mini .el-color-picker__trigger{
  width: 22px;
  height: 22px;
}
.avue-dialog .el-dialog__header{
  border-color: #333;
}
.avue-dialog__footer{
  background-color: transparent;
  border-color:#333;

}
.avue-tip-title {
  font-size: 14px;
  font-weight: 700;
  color: #2681ff;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 40px;
}
.avue-draggable__item{
  height: 100%;
}
